var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Select", {
    attrs: {
      value: _vm.value,
      "allow-clear": "",
      "show-search": "",
      "filter-option": false,
      loading: _vm.loading,
      disabled: _vm.disabled,
      options: _vm.options,
      "label-in-value": true
    },
    on: {
      search: _vm.onSearch,
      change: _vm.onChange,
      scrollEnd: _vm.onScrollEnd
    },
    scopedSlots: _vm._u([
      {
        key: "option",
        fn: function(ref) {
          var option = ref.option
          return [_vm._v(_vm._s(option.label))]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }